import { getAssetUrl, getHandlingFee, useDeviceQuery } from "@/utils";
import { useTranslation } from "next-i18next";
import {
  Badge,
  Card,
  Flex,
  Group,
  NumberFormatter,
  Skeleton,
  Stack,
  Text,
  Title,
  rem,
} from "@mantine/core";
import Image from "next/image";
import { CURRENCIES } from "@/constants/locale";
import dayjs from "dayjs";
import { SEARCH_DEVICE } from "@/constants";
import { useRouter } from "next/router";

export function RouteCard({
  route,
  price,
  currency,
  hasImg,
}: {
  route: any;
  price: number;
  currency: string;
  hasImg?: boolean;
}): JSX.Element {
  const { t, i18n } = useTranslation();
  const [isMobile] = useDeviceQuery();
  const router = useRouter();
  const { query } = router;
  const handlingFee = getHandlingFee(i18n.language);

  const searchUrl = (route: any) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_NAVITIME_SUBMIT_URL}/${i18n.language}/booking/jr/search`;
    const date = dayjs(new Date()).add(5, "days").format("YYYYMMDD");

    const cid = `?cid=${
      query.cid ?? (isMobile ? SEARCH_DEVICE.SP : SEARCH_DEVICE.PC)
    }`;
    const via = query.via ? `?via=${query.via}` : "";
    const depStationName = `&depStationName=${route.from?.name}`;
    const departure = `&departure=${route.from?.code}`;
    const arvStationName = `&arvStationName=${route.to?.name}`;
    const arrival = `&arrival=${route.to?.code}`;
    const searchDate = `&date=${date}`;
    const searchType = "&searchType=one-way";
    return `${
      baseUrl +
      cid +
      via +
      depStationName +
      departure +
      arvStationName +
      arrival +
      searchDate +
      searchType
    }`;
  };

  return (
    <Card
      key={route.id}
      href={searchUrl(route)}
      component="a"
      withBorder
      className={`active:top-0.5 hover:border-dark-9 rounded-md transition
                  ease-in-out cursor-pointer justify-center`}
      p={{ base: "sm", md: "md" }}
      mb="md"
      mih={rem(115)}
      rel="noopener"
    >
      <Flex justify="space-between" align="center" w="100%">
        <Flex
          gap="xs"
          w="100%"
          h="100%"
          justify="space-between"
          direction={{ base: hasImg ? "column" : "row", md: "column" }}
        >
          <Stack gap={rem(4)} className="flex-1">
            <Badge fw={500} fs={rem(11)} radius={rem(4)} px={rem(5)}>
              {t(`common:train_type.${route.type}`)}
            </Badge>
            <Title
              order={3}
              fw={700}
              ta="left"
              c="dark.9"
              className="text-base"
            >
              {t(
                `locations:prefectures.${route.from?.prefecture}.${route.from?.name}`,
              )}{" "}
              -{" "}
              {t(
                `locations:prefectures.${route.to?.prefecture}.${route.to?.name}`,
              )}
            </Title>
            <Text className="text-sm md:text-xs">
              {"("}
              {t(
                `locations:prefectures.${route.from?.prefecture}.${route.from?.prefecture}`,
              )}{" "}
              -{" "}
              {t(
                `locations:prefectures.${route.to?.prefecture}.${route.to?.prefecture}`,
              )}
              {")"}
            </Text>
          </Stack>
          <Flex
            gap={rem(5)}
            align="flex-end"
            justify={{
              base: hasImg ? "flex-start" : "center",
              md: "flex-start",
            }}
            direction={{ base: hasImg ? "row" : "column", md: "row" }}
          >
            {price ? (
              <>
                <Group gap={rem(5)} align="flex-end">
                  <Text
                    c="orange.9"
                    fw={700}
                    className="text-sm md:text-xs !leading-normal"
                  >
                    {CURRENCIES.find((item) => item.key === currency)?.symbol}
                  </Text>
                  <NumberFormatter
                    className="text-lg md:text-base font-bold text-orange-9 !leading-none"
                    value={price}
                    thousandSeparator
                    decimalScale={
                      ["USD", "AUD", "EUR"].includes(currency) ? 2 : 0
                    }
                    fixedDecimalScale
                  />
                </Group>
                {currency !== CURRENCIES[0].key && (
                  <Text className="text-sm md:text-xs !leading-normal">
                    {"("}
                    <NumberFormatter
                      value={route.price + handlingFee}
                      prefix="JPY "
                      thousandSeparator
                    />
                    {")"}
                  </Text>
                )}
              </>
            ) : (
              <Skeleton w={rem(150)} h="md" />
            )}
          </Flex>
        </Flex>
        {hasImg && (
          <Image
            src={getAssetUrl(route.img)}
            width={80}
            height={108}
            alt={`From ${route.from.name} To ${route.to.name}`}
            className="rounded-md object-cover h-[118px] md:h-[108px]"
          />
        )}
      </Flex>
    </Card>
  );
}
